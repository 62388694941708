<template>
  <div class="container">
    <a :href="'/login'" class="btn btn-primary">Volver a inicio</a>

    <h1 class="text-center">Derecho de Desistimiento</h1>
    <p>
      El usuario tiene derecho a cancelar un pedido sin dar ninguna explicación. Este derecho es el derecho de desistimiento establecido por el art. 102 el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.
    </p>
    <p>
      Para cancelar un pedido puede usar el formulario adjunto (modelo de desistimiento) o pueden notificarlo por correo electrónico dirigiéndose a <a href="mailto:fmoreno@ventancatgrup.com">fmoreno@ventancatgrup.com</a> o por correo postal a la dirección Ramon Muntaner 5 Lliçà d ´Amunt CP 08186 Barcelona.
    </p>
    <p>
      Si nos envía un correo electrónico o una comunicación por escrito, por favor, incluya los detalles de su pedido para ayudarnos a identificarlo.
    </p>
    <p>
     El plazo de desistimiento expirará a los 14 días naturales del día que usted o un tercero por usted indicado, distinto del transportista, adquirió la posesión material de los bienes o en caso de que los productos que componen el pedido se entreguen por separado, a los 14 días naturales del día que usted o un tercero por usted indicado, distinto del transportista, adquirió la posesión material del último de los mencionados productos.
    </p>
    <p>
      Una vez ejercido el derecho al desistimiento el usuario deberá remitir a la dirección indicada los productos entregados asumiendo por tanto los costes de devolución. Por favor, tenga en cuenta que si usted decide devolvernos los artículos a portes debidos estaremos autorizados a cargarle los gastos en que podamos incurrir.
    </p>
    <p>
      El derecho a desistir del contrato será de aplicación exclusivamente a aquellos productos que se devuelvan en las mismas condiciones en que el cliente los recibió. No se hará ningún reembolso si el producto ha sido usado más allá de la mera apertura del mismo, de productos que no estén en las mismas condiciones en las que se entregaron o que hayan sufrido algún daño, por lo que deberá ser cuidadoso con el/los producto/s mientras estén en su posesión. Por favor, devuelva el artículo usando o incluyendo todos sus envoltorios originales con todas las etiquetas. Rogamos no retiren las etiquetas hasta estar seguros de querer quedarse con cada artículo.
    </p>    
    <p>
      Tras examinar el artículo le comunicaremos si tiene derecho al reembolso de las cantidades abonadas. Si la devolución incluye la totalidad de la compra, se ha seguido el procedimiento correcto y los productos están nuevos y sin usar, procederemos al reembolso del importe total de la compra más los gastos de envío en un plazo de 14 días desde que recibamos el pedido cancelado.
    </p>      
    <p>
      Si la devolución solo incluye una parte del pedido, se ha seguido el procedimiento correcto y los productos están nuevos y sin usar, solo le reembolsaremos el valor de los productos devueltos, sin los gastos de envío.
    </p>

    <p> (sólo debe cumplimentar y enviar el presente formulario si desea desistir del contrato)</p>
    <p>     
      A la atención de --------------------------------------------------------------------------------------- con NIF/CIF -----------------------------------------, domicilio sito en -------------------------------------------------------------------------------------- y con correo electrónico----------------------------------------------------------------y teléfono ---------------, en adelante -------------------------------------------------------------------------------------.
      Por la presente le comunico que desisto de mi contrato de venta del producto/productos indicados: (descripción y referencia tal como se indica en el contrato)
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      …………………………………………………………………………………………………………………………………………………
      Pedido realizado con fecha.............................. 
      Pedido recibido con fecha...............................
      Nombre del consumidor:..................................
      Dirección del consumidor:...............................
      Firma del consumidor (solo si el presente formulario se presenta en papel)
      </p>
    
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss">
  @import "@core/scss/vue/pages/page-pricing.scss";
  .container{
    margin-top: 40px;
    padding: 40px;
    text-align: justify;
  }
  h1{
    padding-bottom: 20px;
  }
</style>